import React from "react";

import "./Memories.scss";

function Memories() {
  return (
    <div className="memories">
      <div className="section-title-with-underline">
        <div className="box-outline">Photos and Videos</div>
      </div>

      <div className="notification">
        Keep an eye out in this section for
        <br />
        photos and videos after the big day!
      </div>
    </div>
  );
}

export default Memories;
