import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import "./Rsvp.scss";

const RSVPStatus = {
  NA: "NA",
  YES: "YES",
  NO: "NO",
  MAYBE: "MAYBE",
  ERROR: "ERROR",
};

type RsvpForm = {
  name: string;
  email: string;
  response: string;
  preferVeg: boolean;
  comments: string;
};

type RSVPFormChangeEvent = {
  target: {
    value: string;
    checked?: boolean;
  };
};

function Rsvp() {
  const [rsvpStatus, setRsvpStatus] = useState<keyof typeof RSVPStatus>("NA");

  const [form, setForm] = useState<RsvpForm>({
    name: "",
    email: "",
    response: "",
    preferVeg: false,
    comments: "",
  });

  const handleChange = (fieldName: string) => (event: RSVPFormChangeEvent) => {
    if (event.target.checked !== undefined) {
      const updatedState = {
        ...form,
        [fieldName]: event.target.checked,
      };

      setForm(updatedState);
    } else {
      const updatedState = {
        ...form,
        [fieldName]: event.target.value,
      };

      setForm(updatedState);
    }
  };

  const handleSubmit = async () => {
    if (form.name === "" || form.email === "" || form.response === "") {
      setRsvpStatus("ERROR");
      return;
    }

    setRsvpStatus(form.response.toUpperCase() as keyof typeof RSVPStatus);
  };

  return (
    <div className="rsvp">
      <div className="section-title-with-underline">
        <div className="box-outline">RSVP</div>
      </div>

      <div className="rsvp-form">
        {rsvpStatus === "YES" && (
          <Alert variant="filled" severity="success">
            <strong>Thank you for your RSVP!</strong>
            <br />
            We look forward to seeing you on our big day.
          </Alert>
        )}

        {rsvpStatus === "MAYBE" && (
          <Alert
            variant="filled"
            severity="info"
            style={{ marginBottom: "30px" }}
          >
            Thank you for your RSVP!
            <br />
            You have set your response to <strong>Maybe</strong>. We sincerely
            hope that you can make it.
          </Alert>
        )}

        {rsvpStatus === "NO" && (
          <Alert variant="filled" severity="warning">
            We have received your RSVP!
            <br />
            We are sorry that you cannot make it. We will miss you!
          </Alert>
        )}

        {(rsvpStatus === "NA" || rsvpStatus === "ERROR") && (
          <>
            <TextField
              label="Your Name"
              variant="standard"
              fullWidth={true}
              style={{ marginBottom: "30px" }}
              value={form.name}
              onChange={handleChange("name")}
            />

            <TextField
              label="Your Email"
              variant="standard"
              fullWidth={true}
              style={{ marginBottom: "30px" }}
              value={form.email}
              onChange={handleChange("email")}
            />

            <FormControl
              variant="filled"
              style={{ width: "100%", marginBottom: "30px" }}
            >
              <InputLabel id="rsvp-select">RSVP Response</InputLabel>
              <Select
                labelId="rsvp-select"
                fullWidth={true}
                value={form.response}
                onChange={handleChange("response")}
              >
                <MenuItem value="yes">Yes, I'll be there</MenuItem>
                <MenuItem value="no">Sorry, can't make it</MenuItem>
                <MenuItem value="maybe">I am not sure at the moment</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              style={{ marginBottom: "30px" }}
              control={
                <Checkbox
                  checked={form.preferVeg}
                  onChange={handleChange("preferVeg")}
                />
              }
              label="I prefer vegetarian food"
            />

            <TextField
              style={{ marginBottom: "30px" }}
              label="Additional Comments (Optional)"
              fullWidth={true}
              multiline={true}
              rows={4}
              value={form.comments}
              onChange={handleChange("comments")}
            />

            <Button
              variant="outlined"
              fullWidth={true}
              onClick={handleSubmit}
              style={{ marginBottom: "20px" }}
            >
              Submit Response
            </Button>

            {rsvpStatus === "ERROR" && (
              <Alert variant="filled" severity="error">
                There was an error while submitting your RSVP!
                <br />
                Please ensure that you have filled out all the required fields.
              </Alert>
            )}
          </>
        )}

        {/* <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfO1Yc9U5Z5Ll0J7W9Q2P4QwYh7Z1Z8iW0KvZk0jQ5Bb2v9CQ/viewform?embedded=true"
          width="100%"
          height="1000"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe> */}
      </div>
    </div>
  );
}

export default Rsvp;
