import React from "react";

import "./VideoHeader.scss";

import VideoBanner from "../videos/video-compressed.mp4";

function VideoHeader() {
  return (
    <div className="video-header">
      <video autoPlay={true} muted={true} loop={true}>
        <source src={VideoBanner} type="video/mp4" />
      </video>
      <div className="text-wrapper">
        <div className="box-outline">
          <div className="text-name">Sayan & Gitika</div>
          <div className="text-title">We're getting married</div>
        </div>
      </div>
    </div>
  );
}

export default VideoHeader;
