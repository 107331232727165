import React from "react";

import "./OurStory.scss";
// import SunflowerSvg from "../images/sunflower.svg";
import StoryImage1 from "../images/our-story-1.jpg";
import StoryImage2 from "../images/our-story-2.jpg";
import StoryImage3 from "../images/our-story-3.jpg";
import FloralBanner from "../images/floral-banner.jpg";

function OurStory() {
  return (
    <div className="our-story">
      <div className="section-title">Our Story</div>
      <img src={FloralBanner} alt="floral-banner" className="floral-banner" />

      <div className="story-wrapper story-image-right">
        <div className="text-section">
          Sayan and Gitika met online in late 2020, after both of them
          had moved back to their hometowns due to the COVID-19 lockdowns. They
          were immediately drawn to each other's profiles, and started chatting.
          <br />
          <br />
          As they got to know each other better, they realized that they had a
          lot in common. They both loved to travel, try out new cuisine, and
          spend time outdoors. They also had similar values and goals in life.
          <br />
          <br />
          One day, while they were talking about their lives in Bangalore, they
          realized that they had lived within 150 meters of each other for quite
          some time, but had never actually met. They were both amazed by the
          coincidence, and it made them realize that their connection was truly
          meant to be.
        </div>

        <div className="image-section">
          <div className="image-frame" style={{ transform: "rotate(2.5deg)" }}>
            <img src={StoryImage1} alt="story" />
          </div>
        </div>
      </div>

      <div className="story-wrapper story-image-left">
        <div className="image-section">
          <div
            className="image-frame"
            style={{ transform: "rotate(-4.5deg) translate(-20px, 5px)" }}
          >
            <img src={StoryImage3} alt="story" />
          </div>

          <div
            className="image-frame"
            style={{ transform: "rotate(3.5deg) translate(15px, -5px)" }}
          >
            <img src={StoryImage2} alt="story" />
          </div>
        </div>

        <div className="text-section">
          They started dating soon after, and their love for each other only
          grew stronger with each passing day. They knew that they wanted to
          spend the rest of their lives together, and wanted to do something
          special to commemorate their love.
          <br />
          <br />
          So, they decided to go on an adventure trip to Gurudongmar Lake in
          North Sikkim, believed to be the highest lake in the world. They hiked
          through the rugged terrain, it was an incredible experience.
          <br />
          <br />
          Having arrived at the lake, Sayan took Gitika to a secluded spot on
          the shore of the lake. He got down on one knee and proposed to her.
          Gitika said yes.
        </div>
      </div>
    </div>
  );
}

export default OurStory;
