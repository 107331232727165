import React, { ReactNode } from "react";

import "./Venue.scss";

type GoogleMapComponentProps = {
  mapUrl: string;
  title: ReactNode;
  venue: ReactNode;
  address: ReactNode;
  time: ReactNode;
};

function GoogleMapComponent(props: GoogleMapComponentProps) {
  return (
    <div className="google-maps-component">
      <div className="map-widget">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="gmaps"
              className="gmap_iframe"
              width="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              src={props.mapUrl}
            ></iframe>
          </div>
        </div>
      </div>

      <div className="map-description">
        <div className="title">{props.title}</div>
        <div className="text-wrapper">
          <div className="subtitle">Where</div>
          <div className="text">
            <strong>{props.venue}</strong>
            <br />
            {props.address}
          </div>
        </div>
        <div className="text-wrapper">
          <div className="subtitle">When</div>
          <div className="text">{props.time}</div>
        </div>
      </div>
    </div>
  );
}

function Venue() {
  return (
    <div className="venue">
      <div className="section-title-with-underline">
        <div className="box-outline">Event & Venue</div>
      </div>

      <GoogleMapComponent
        mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.2303990512833!2d88.34177307510045!3d22.607869931677076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277de312acb01%3A0x2c8d1777ffae2124!2sKrishna%20Bhawan!5e0!3m2!1sen!2sus!4v1695153959541!5m2!1sen!2sus"
        title="Wedding"
        venue="Krishna Bhawan"
        address={
          <>
            Near Zilla Court Bus Stop
            <br />
            Grand Trunk Road
            <br />
            Salkia, Howrah - 711106
          </>
        }
        time={
          <>
            Monday, 27th November, 2023
            <br />
            5:00 PM Onwards
          </>
        }
      />

      <br />
      <br />

      <GoogleMapComponent
        mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12534.44840126634!2d88.4652277381132!3d22.697214884007185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f4feacee32b%3A0xba8db04a01acbc7!2sImperial%20Banquet%20%2C%20Savitri!5e0!3m2!1sen!2sus!4v1695153859511!5m2!1sen!2sus"
        title="Reception"
        venue="Imperial Banquet"
        address={
          <>
            Near Rubber Factory Bus Stop
            <br />
            Jessore Road, Basunagar
            <br />
            Madhyamgram, Kolkata - 700129
          </>
        }
        time={
          <>
            Thursday, 30th November, 2023
            <br />
            6:00 PM Onwards
          </>
        }
      />
    </div>
  );
}

export default Venue;
