import React from "react";

import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-text-1">With Love,</div>
      <div className="footer-text-2">Sayan & Gitika</div>
    </div>
  );
}

export default Footer;
