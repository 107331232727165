import React from "react";

import "./App.scss";

import VideoHeader from "./components/VideoHeader";
import OurStory from "./components/OurStory";
import Venue from "./components/Venue";
import Rsvp from "./components/Rsvp";
import Memories from "./components/Memories";
import Footer from "./components/Footer";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <VideoHeader />
        <OurStory />
        <Venue />
        <Rsvp />
        <Memories />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
